<template>
	<div style="min-height: 500px">

		<div class="fix">
			<div class="side-info">
				<button class="side-info-close"><i class="fal fa-times"></i></button>
				<div class="side-info-content">
					<div class="mobile-menu"></div>
				</div>
			</div>
		</div>
		<div class="offcanvas-overlay"></div>

		<!-- page title start  -->
		<section class="page__title  fix text-center">
			<div class="slider__shape">
				<img class="shape triangle" src="/static/picture/topelips.png" alt="topshape">
				<img class="shape dotted-square" src="/static/picture/rightelips.png" alt="rightshape">
				<img class="shape solid-square" src="/static/picture/circleelips.png" alt="solid-square">
			</div>
			<div class="container">
				<div class="row">
					<div class="col-xl-12">
						<div class="page__title-content pt-170">
							<h2 class="page_title">和有趣的人 做正确的事</h2>
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
                    <p style="color: white">
                        微行网络科技，致力于打造旅游及生活服务的爆品新零售电商平台，专注于社交模式创销与爆款<br/>
                        的打造，整合精准流量式营销，为旅游及生活娱乐等产品提供流量的解决方案；深度围绕全球旅<br/>
                        游、机票、酒店、景区、度假村等项目，提供销售渠道、推广、包装为一体的解决方案。
                    </p>
                </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- page title end  -->

		<div style="margin: 32px">


			<div class="container" style="max-width: 800px;">
				<h2 style="margin: 32px 0;">招聘岗位</h2>

				<template v-for="item in jobs">
					<div class="job-card wow fadeInUp">
						<div class="job-title">{{item.title}}</div>
						<div class="duty-title">工作职责</div>
						<div class="info" v-html="item.duty"></div>
						<div class="duty-title">工作要求</div>
						<div class="info" v-html="item.desc2"></div>
					</div>
				</template>

			</div>

		</div>
      <remote-script src="/static/js/main.js"></remote-script>
	</div>
</template>

<script>
	import '@/utils/importJs.js'
	import {PostApi} from '@/api'

	export default {
		name: "Business",
		data() {
			return {
				title: "",
				content: "",

				jobs: [
					{
						title: "国内旅游计调",
						duty: "<p>1.定制公司旅行线路中长期规划开发。<br/>" +
								"2.根据市场产品需求走向， 对已有旅行产品持续进行管理，优化提升用户体验，确定用户市场和定价策略.<br/>" +
								"3.挖掘国内优质地接社，具有议价能力降低采购成本.<br/>" +
								"4.会拆解、会复盘、会提炼、会优化做事有自己的方法论和逻辑。<br/></p>",
						desc2: "<p>1.大专以上学历，了解和熟悉旅游行业和有行业资源者优先；（国内计调熟手） <br/>" +
								"2.工作认真细心、有责任心、较强的服务分享意识、商务洽谈能力；<br/>" +
								"2.优秀的沟通能力，良好的协作意识，善于发现分析解决问题.<br/>" +
								"4.热情努力，喜欢、热爱旅行、热爱行业。 </p>"
					},
					{
						title: "UI设计师",
						duty: "<p>独立负责产品的App、Web、H5等界面视觉设计；<br/>" +
								"2.参与产品的交互设计及优化，注重细节，提高产品用户体验和视觉效果；<br/>" +
								"3.灵活提供设计解决方案，并与产品、研发团队充分沟通协作，把控效果的最终实现效果；<br/>" +
								"4.整理和分析用户行为数据，充分了解用户需求，跟踪和评估产品用户体验，对界面进行持续的设计优化。<br/></p>",
						desc2: "<p>1.大专以上学历，计算机、视觉设计、平面设计、广告设计等相关专业； <br/>" +
								"2.工作认真细心、有责任心、较强的服务分享意识、商务洽谈能力；<br/>" +
								"3.优秀的沟通能力，良好的协作意识，善于发现分析解决问题.<br/>" +
								"4.热情努力，喜欢、热爱旅行、热爱行业。 </p>"
					},
					{
						title: "平台客服",
						duty: "<p>1.熟悉电子商务礼仪,对线上客服工作有一定了解；<br/>" +
								"2.负责解答客户针对平台旅游产品的咨询，及时、有效、妥善的处理客户的各种问题；<br/>" +
								"3.反应灵敏，会基本的电脑操作，能保证在线回复率，应答速率；<br/>" +
								"4.熟悉公司平台操作及相关产品，能够和同事进行配合和交接；<br/>" +
								"5.服从公司管理，切实维护公司利益。</p>",
						desc2: "<p>性格开朗，耐心细致，及时解决用户的问题； <br/>" +
								"2.善于沟通，具有良好的团队精神和责任心；<br/>" +
								"3.对基本的电脑操作要熟练、打字速度快；<br/>" +
								"4.热爱旅游，有较强的学习能力；<br/>" +
								"5.对电子商务、线上客服工作有一定的了解，经验不限。 </p>"
					}
				]
			}
		},
		created() {
			// this.loadPageData();
		},
		methods: {
			loadPageData() {
				let payload = {
					name: "business"
				}
				PostApi.getNewsDetail(payload).then((respData) => {
					if (respData.c === 0) {
						this.title = respData.title;
						this.content = respData.data.content;
					}
				}).catch((err) => {
					// ToastUI.clearToast();
				});
			},
		}

	}
</script>

<style scoped lang="scss">

	.job-card {
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
		text-align: left;
		padding: 16px 20px;
		margin-top: 32px;
		margin-bottom: 32px;

		.job-title {
			font-size: 36px;
			color: #333333;
			line-height: 44px;
			font-weight: 600;
			margin-bottom: 32px;
		}

		.duty-title {
			text-align: left;
			font-size: 28px;
			color: #333333;
			line-height: 28px;
			margin-bottom: 20px;

		}

		.info {
			font-size: 16px;
			color: #333333;
			line-height: 28px;
			margin-bottom: 16px;
		}
	}
</style>